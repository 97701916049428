<!-- 培训管理-进入课程学习-视频课程 -->
<template>
  <div class="page">
    <div class="video-left">
      <div class="video-left-link" @click="gobackBtn">
        <img src="../../../../public/static/back_v.png" class="video-left-link-img" alt="" />
        <div class="video-left-link-text">返回课程列表</div>
      </div>
      <div class="video-left-list">
        <div v-for="(item,index) in list">
          <div class="video-left-listitem" :data-videoTitleIndex="index" :data-currid="index+1"  @click="openList($event)" >
            <div class="video-left-listitem-num">{{index+1}}</div>
            <div class="video-left-listitem-text">{{item.name}}</div>
            <img src="../../../../public/static/up.png" v-if="item.child.length > 0 && currId != index+1" class="video-left-listitem-icon" alt="">
            <img src="../../../../public/static/down.png" v-if="currId == index+1" class="video-left-listitem-icon" alt="">
          </div>
          <div v-for="(arry,vindex) in item.child">
            <div class="video-left-listitem-child" :data-currvideo="arry.path" :data-videoIndex="vindex" :data-videoId="arry.id" :data-curriculumId="arry.curriculum_id" :class="[currId != index+1 ?'video-left-listitem-child-show': '']" @click="openListVideo($event)" >
              <div class="video-left-listitem-childnum-no" v-if="arry.is_study == 0"></div>
              <div class="video-left-listitem-childnum-yes" v-if="arry.is_study == 1"></div>
              <div  class="video-left-listitem-no" v-if="arry.is_study == 0">未学完</div>
              <div  class="video-left-listitem-yes"  v-if="arry.is_study == 1">已学完</div>
              <div class="video-left-listitem-text-no" v-if="arry.is_study == 0">{{arry.name}}</div>
              <div class="video-left-listitem-text-yes" v-if="arry.is_study == 1">{{arry.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video-right">
      <video width="100%" height="700" :src="curVideo" controls @click='change()'  ref="video" @timeupdate="handleTimeUpdate" @ended="playNext(videoTitleIndex,videoIndex)" @loadedmetadata="onLoadedMetadata"> </video>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        // 列表
        list: [],
        isShow: false,
        currId:0,
        curVideo:'',
        videoId:0,
		curriculumId:0,
		orderId:0,
		duration: 0,
	    currentTime: 0,
		afterVideoIndex:'',
		videoIndex:'',
		videoTitleIndex:'',
		afterVideoTitleIndex:'',

      };
    },
	mounted() {
		let that = this;
	    /* const video = that.$refs.video;
		console.log(video);
		video.addEventListener('loadedmetadata', () => {
		  that.duration = parseInt(video.duration);
		}); */
		//video.addEventListener('timeupdate', that.handleTimeUpdate);
		window.addEventListener('beforeunload', that.onBeforeUnload)
	    
	},
	onBeforeUnload() {
	    // 在这里进行操作
		console.log('浏览器刷新');
	},
	beforeDestroy() {
		console.log('页面卸载了');
	},
	destroyed() {
		let that = this;
		let video = that.$refs.video;
		if(that.videoId){
			//视频id存在的时候请求学习记录接口
			let param = {
				id:that.videoId,
				curriculum_id:that.curriculumId,
				order_id:that.orderId,
				total_progress:that.duration,
				progress:that.currentTime,
			};
			that.videoStudy(param);
		}
		console.log('页面隐藏了');
	},
	deactivated(){
		console.log('组件失去激活状态');
	},
	beforeUnmount() {
		let that = this;
	    let video = this.$refs.video;
		if(that.videoId){
			//视频id存在的时候请求学习记录接口
			let param = {
				id:that.videoId,
				curriculum_id:that.curriculumId,
				order_id:that.orderId,
				total_progress:that.duration,
				progress:that.currentTime,
			};
			that.videoStudy(param);
		}
	    
	},
    beforeCreate() {},
    created() {
      let that = this;
	  that.orderId = that.$route.query.order_id;
      that.getVideoLists();
    },
	watch: {
	    $refs: {
	      video: {
	        handler(value) {
	          if (value.ended) {
	            this.playNext()
	          }
	        }
	      }
	    }
	},
    methods: {
		onLoadedMetadata() {
			  let that = this;
		      console.log('视频已加载完毕');
			  let video = this.$refs.video;
			  
			  //视频加载完成，获取视频总秒数，请求接口
			  console.log(that.duration);
			  that.duration = parseInt(video.duration);//总时长秒
			  video.play();
			  console.log(video.onloadedmetadata);
			  console.log(video.onloadedmetadata);
			  video.removeEventListener('loadedmetadata', this.onLoadedMetadata);
		},
		//播放获取播放秒数
		handleTimeUpdate() {
			let that = this;
		    let video = that.$refs.video;
		    that.currentTime = parseInt(video.currentTime);
			  //记录学习
			  /* let param = {
			  	id:that.videoId,
			  	total_progress:that.duration,
			  	progress:that.currentTime,
			  };
			  that.videoStudy(param); */
		},
		//即将播放下一个视频
		playNext(index,vindex) {
			let that = this;
			//视频id存在的时候请求学习记录接口
			let param = {
				id:that.videoId,
				curriculum_id:that.curriculumId,
				order_id:that.orderId,
				total_progress:that.duration,
				progress:that.currentTime,
			};
			if (index === that.videoTitleIndex && vindex === this.videoIndex) {
				// 当前播放的视频已经结束，计算下一个视频的索引
				console.log('videoIndex+1:'+that.videoIndex)
				let nextVideoIndex = parseInt(that.videoIndex) + parseInt(1);
				let nextChapterIndex = that.videoTitleIndex;
				if (nextVideoIndex >= this.list[nextChapterIndex].child.length) {
					nextChapterIndex++;
					nextVideoIndex = 0;
					if (nextChapterIndex >= this.list.length) {
					  // 没有更多视频可播放了，可以根据需要添加提示框等相关逻辑
					  return;
					}
					console.log('下一个视频'+nextChapterIndex+'==='+nextVideoIndex);
					that.curVideo = this.list[nextChapterIndex].child[nextVideoIndex].path;
					that.videoId = this.list[nextChapterIndex].child[nextVideoIndex].id;
					that.curriculum_id= this.list[nextChapterIndex].child[nextVideoIndex].curriculumId;
				}else{
					//console.log('下一个视频'+nextChapterIndex+'==='+nextVideoIndex);
					that.curVideo = this.list[nextChapterIndex].child[nextVideoIndex].path;
					that.videoId = this.list[nextChapterIndex].child[nextVideoIndex].id;
					that.curriculum_id = this.list[nextChapterIndex].child[nextVideoIndex].curriculumId;
				}
				console.log(this.list[nextChapterIndex].child[nextVideoIndex]);
				this.afterVideoTitleIndex = nextChapterIndex;
				this.afterVideoIndex = nextVideoIndex;
				console.log(this.afterVideoTitleIndex+'==='+this.afterVideoIndex);
				//let video = that.$refs.video;
				//video.addEventListener('loadedmetadata', that.loadedmetadataFunc.bind(this, video));
				/* video.addEventListener('loadedmetadata', () => {
					//视频加载完成，获取视频总秒数，请求接口
					that.duration = parseInt(video.duration);//总时长秒
					console.log(that.duration);
					video.play();
				}); */
				
			}
			
			that.videoStudy(param);
		},
      //获取本地视频
      getVideoLists() {
        let that = this;
        that.loading = true;
        let curriculum_id = that.$route.query.curriculum_id;
        let param = {
          curriculum_id
        };
        that.$request.getVideoLists(param).then((res) => {
          if (res.code == 200) {
            that.list = res.datas;

          }
        })
      },
	  //记录学习进度
	  videoStudy(param){
		  let that = this;
		  
		that.$request.videoStudy(param).then((res) => {
		    if(res.datas.finish == 1){
				that.list[that.videoTitleIndex].child[that.videoIndex]['is_study'] = 1;
				
			}
			that.videoIndex = that.afterVideoIndex;
			if(that.afterVideoTitleIndex){
				that.videoTitleIndex = that.afterVideoTitleIndex;
			}
			
		})
	  },
      //返回
      gobackBtn() {
        let that = this;
         that.$router.push({
           path: '/user/training/list',
         });
      },
      // 打开列表
      openList(e){
        let that = this;
        that.currId = e.currentTarget.dataset.currid;
		if(that.videoTitleIndex){
			that.afterVideoTitleIndex = e.currentTarget.dataset.videotitleindex;
		}else{
			that.videoTitleIndex = e.currentTarget.dataset.videotitleindex;
		}
		
        console.log('选择的大标题'+that.videoTitleIndex);
      },
      // 播放视频
      openListVideo(e){
        let that = this;
		//当用户点击视频时，把上一个的视频学习状态提交给后端
		
		if(that.videoId){
			//视频id存在的时候请求学习记录接口
			let param = {
				id:that.videoId,
				curriculum_id:that.curriculumId,
				order_id:that.orderId,
				total_progress:that.duration,
				progress:that.currentTime,
			};
			that.afterVideoIndex = e.currentTarget.dataset.videoindex;
			
			that.videoStudy(param);
		}else{
			that.videoIndex = e.currentTarget.dataset.videoindex;
		}
		if(that.videoId != e.currentTarget.dataset.videoid){
			that.duration = 0;
			that.currentTime = 0;
			that.curVideo = e.currentTarget.dataset.currvideo;
			that.videoId = e.currentTarget.dataset.videoid;//当前视频id
			that.curriculumId = e.currentTarget.dataset.curriculumid;//当前视频id
			let video = that.$refs.video;
			
			//that.loadedmetadata(video);
			//video.addEventListener('loadedmetadata', that.loadedmetadataFunc.bind(this, video));
			
		}
	   },
	   
	   loadedmetadataFunc(video){
		   let that = this;
		   
		   //视频加载完成，获取视频总秒数，请求接口
		   console.log(that.duration);
		   that.duration = parseInt(video.duration);//总时长秒
		   video.play();
		   console.log(video.onloadedmetadata);
		   video.removeEventListener('loadedmetadata', that.loadedmetadataFunc.bind(this, video));
		   console.log(video.onloadedmetadata);
	   },
		change(curVideo) {
          let that = this;
		}
    }
  };
</script>

<style scoped lang="css">
  .page {
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 100vh;
    width: clac(100% - 2px) !important;
    background-color: #fff;
  }

  .video-left {
    width: 20%;
  }

  .video-left-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0074FF;
    color: #fff;
    font-size: 1.25rem;
  }

  .video-left-link-img {
    width: 2rem;
    height: 2rem;
  }

  .video-left-link-text {
    line-height: 4.5;
  }

  .video-left-list {
    padding: 1.5rem 1rem;
  }

  .video-left-listitem {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .video-left-listitem-num {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    font-size: 0.5rem;
    border-radius: 50%;
    border: 1px solid #ccc;
    text-align: center;
    line-height: 1.2rem;
  }

  .video-left-listitem-text {
    width: 18rem;
  }

  .video-left-listitem-icon {
    width: 1rem;
    height: 1rem;
  }
  .video-left-listitem-child{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    width: 100%;
    font-size: 0.7rem;
  }
  .video-left-listitem-childnum-no{
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  .video-left-listitem-childnum-yes{
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    color: #0074FF;
    border: 1px solid #0074FF;
  }
  .video-left-listitem-child-show{
    display: none;
  }
  .video-left-listitem-no{
    padding: 0.2rem;
    background-color: #efefef;
    color: #333;
  }
  .video-left-listitem-yes{
    padding: 0.2rem;
    background-color: #D9ECFF;
    color: #0074FF;
  }
  .video-left-listitem-text-no {
    width: 18rem;
  }
  .video-left-listitem-text-yes {
    width: 18rem;
     color: #0074FF;
  }

  .video-right {
    flex-grow: 1;
    padding: 2rem;
    background-color: #efefef;
  }
</style>
